<template>
    <div class="view">
        <el-tabs v-model="webType" @tab-click="handleClick">
            <el-tab-pane label="订单列表" name="list"></el-tab-pane>
            <el-tab-pane label="退款/售后" name="afterSales"></el-tab-pane>
        </el-tabs>
        <div class="pa24" v-if="webType === 'list'">
            <div class="d-flex">
                <div>
                    <el-radio style="margin-right: 0;" v-model="orderStatusValue" label="all" @change="searchFun"
                        border>
                        全部订单
                    </el-radio>
                    <el-radio style="margin-right: 0;" v-model="orderStatusValue" :label="id" @change="searchFun"
                        v-for="(name, id) in orderStatus" :key="id" border>
                        {{ name }}
                    </el-radio>
                    <el-input class="w200 ml10 mb10 mr10" placeholder="请输入订单编号" v-model="orderCode" />
                    <el-button type="primary" @click="searchFun">搜索</el-button>
                </div>
            </div>
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                <template v-slot:table>
                    <el-table-column prop="orderNum" align="center" label="订单编号" width="200" />
                    <el-table-column prop="activityName" align="center" label="活动" show-overflow-tooltip />
                    <el-table-column prop="nickeName" align="center" label="购买用户" show-overflow-tooltip />
                    <el-table-column prop="phone" align="center" label="联系方式" show-overflow-tooltip />
                    <el-table-column prop="orderMoney" align="center" label="订单金额" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.orderMoney }}元
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status == 0" type="info">{{ orderStatus[scope.row.orderState]
                                }}</el-tag>
                            <el-tag v-else-if="scope.row.status == 1">{{ orderStatus[scope.row.orderState] }}</el-tag>
                            <el-tag v-else type="danger">{{ orderStatus[scope.row.orderState] }}</el-tag>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
        <div class="pa24" v-if="webType === 'afterSales'">
            <div class="d-flex">
                <div>
                    <el-radio style="margin-right: 0;" v-model="refundStateValue" label="all"
                        @change="afterSalesSearchFun" border>
                        全部订单
                    </el-radio>
                    <el-radio style="margin-right: 0;" v-model="refundStateValue" :label="row.id"
                        @change="afterSalesSearchFun" v-for="(row, index) in refundStateData" :key="index" border>
                        {{ row.label }}
                    </el-radio>
                    <el-input class="w200 ml10 mb10 mr10" placeholder="请输入订单编号" v-model="afterSalesOrderCode" />
                    <el-button type="primary" @click="afterSalesSearchFun">搜索</el-button>
                </div>
            </div>
            <commonTable :tableData="afterSalesTableData" :loading="afterSalesLoading"
                @handleSizeChange="afterSalesHandleSizeChange" @handleCurrentChange="afterSalesHandleCurrentChange"
                :currentPage="afterSalesCurrentPage" :total="afterSalesTotal">
                <template v-slot:table>
                    <el-table-column prop="orderNum" align="center" label="订单编号" width="200" />
                    <el-table-column prop="activityName" align="center" label="活动" show-overflow-tooltip />
                    <el-table-column prop="nickeName" align="center" label="退款用户" show-overflow-tooltip />
                    <el-table-column prop="phone" align="center" label="联系方式" show-overflow-tooltip />
                    <el-table-column prop="payMoney" align="center" label="退款金额" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.payMoney }}元
                        </template>
                    </el-table-column>
                    <el-table-column prop="refundFee" align="center" label="实际退款金额" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div v-if="scope.row.refundFee">{{ scope.row.refundFee / 100 }}元</div>
                            <div v-else>-</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" align="center" label="状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <!-- {{ refundState[scope.row.refundState] }} -->
                            <el-tag v-if="scope.row.status == 1" type="info">{{ refundState[scope.row.refundState]
                                }}</el-tag>
                            <el-tag v-else-if="scope.row.status == 2" type="warning">{{ refundState[scope.row.refundState]
                                }}</el-tag>
                            <el-tag v-else>{{ refundState[scope.row.refundState] }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="80">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.refundState == 2" style="color:#F56C6C" type="text"
                                @click="showUpdateModel(scope.row)">退款
                            </el-button>
                            <div style="height: 35px;line-height: 35px;" v-else>
                                -
                            </div>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="50%" center>
            <el-form :model="refundData" :rules="refundRules" ref="refundData" label-width="100px">
                <el-form-item label="订单号" prop="levelSort">
                    <el-input v-model="refundData.orderNum" type="number" disabled />
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="退款用户" prop="levelSort">
                            <el-input v-model="refundData.nickeName" type="text" disabled />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="退款金额" prop="levelSort">
                            <el-input v-model="showMoney" class="suffixInput" type="number" style="width: 210px"
                                disabled>
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="退款比例 (%)" prop="levelSort">
                    <el-slider v-model="refundData.refundScale" :min="1" :max="100" :marks="marks"
                        @input="watchRefundScale" show-input>
                    </el-slider>
                </el-form-item>
                <div class="pa24">
                    <p class="textc mb10" style="font-size:18px">退款理由</p>
                    <el-input type="textarea" v-model="refundData.refundRemark" rows="5" disabled>
                    </el-input>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="mr10" @click="showModel = false">取 消</el-button>
                <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="refundFun()"
                    icon="el-icon-info" title="确认退还当前比例金额？">
                    <el-button type="primary" slot="reference" :loading="loadingBtn">退 款</el-button>
                </customPopconfirm>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import { getActivityRegisterOrderList, updateRefundState } from "@/api/propertyServices";
export default {
    name: "activityOrder",
    components: {
        commonTable,
        customPopconfirm
    },
    data() {
        return {
            webType: "list",
            //列表配置
            orderStatus: {
                0: "待付款",
                1: "已付款",
                // 2: "订单退款"
                3: "取消订单"
            },
            orderStatusValue: "all",
            orderCode: "",
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            tableData: [],
            //售后服务列表
            refundStateData: [
                // {id:1,label:"未申请"},
                { id: 2, label: "申请中" },
                { id: 3, label: "退款成功" },
                // { id: 4, label: "拒绝退款" },
            ],
            refundState: {
                1: "未申请",
                2: "申请中",
                3: "退款成功",
                // 4: "拒绝退款"
            },
            refundStateValue: "all",
            afterSalesOrderCode: "",
            afterSalesCurrentPage: 1, //当前页
            afterSalesPageSize: 10, //显示条数
            afterSalesLoading: false, //表格加载
            afterSalesTotal: 0, //总条数
            afterSalesTableData: [],
            //模态框配置
            showModel: false,
            modelTitle: "",
            marks: {
                30: '30%',
                50: "50%",
                80: "80%"
            },
            refundData: {//退款数据
                orderId: 0,
                refundScale: "",
                refundRemark: "",
                payMoney: 0,
            },
            refundRules: {

            },
            showMoney: "",
            orderId: 0,
            parkId: 0,
            loadingBtn: false,
        };
    },
    filters: {

    },
    created() {
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        this.getList();
    },
    computed: {
        watchCheckedPark() {
            return this.$store.state.checkedPark
        },
    },
    watch: {
        watchCheckedPark(row) {
            this.parkId = row.parkId;
            this.currentPage = 1;
            this.afterSalesCurrentPage = 1;
            if (this.webType === 'list') {
                this.getList();
            } else {
                this.afterSalesGetList();
            }
        }
    },
    methods: {
        /**@method  */
        watchRefundScale() {
            this.showMoney = (this.refundData.payMoney * (this.refundData.refundScale / 100)).toFixed(2)
        },
        /**@method 列表内容切换 */
        handleClick() {
            if (this.webType === 'list') {
                this.getList();
            } else {
                this.afterSalesGetList();
            }
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                parkId: this.parkId,
                notShowStatus: 2,
            };
            if (this.orderStatusValue != 'all') {
                params.orderState = this.orderStatusValue
            }
            if (this.orderCode) {
                params.orderNum = this.orderCode
            }
            try {
                this.loading = true;
                let res = await getActivityRegisterOrderList(params);
                this.loading = false;

                const { data } = res;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 搜索 */
        afterSalesSearchFun() {
            this.afterSalesCurrentPage = 1;
            this.afterSalesGetList();
        },
        /**@method 获取列表 */
        async afterSalesGetList() {
            let params = {
                pageSize: this.afterSalesPageSize,
                pageNum: this.afterSalesCurrentPage,
                parkId: this.parkId,
                orderState: 2
            };
            //退款状态查询
            if (this.refundStateValue != 'all') {
                params.refundState = this.refundStateValue
            }
            //订单号查询
            if (this.afterSalesOrderCode) {
                params.orderNum = this.afterSalesOrderCode
            }
            try {
                this.afterSalesLoading = true;
                let res = await getActivityRegisterOrderList(params);
                this.afterSalesLoading = false;

                const { data } = res;
                this.afterSalesTableData = data.pageInfo.list;
                this.afterSalesTotal = data.pageInfo.total;
            } catch (error) {
                this.afterSalesLoading = false;
                this.afterSalesTableData = [];
            }
        },
        /**@method 切换行 */
        afterSalesHandleSizeChange(val) {
            this.afterSalesPageSize = val;
            this.afterSalesGetList();
        },
        /**@method 切换下一页 */
        afterSalesHandleCurrentChange(val) {
            this.afterSalesCurrentPage = val;
            this.afterSalesGetList();
        },
        /**@method 退款
         * @param {Object} val - 当前点击行的值
         */
        refundFun() {
            //修改添加会员等级
            this.loadingBtn = true;
            updateRefundState({
                orderId: this.refundData.orderId,
                refundScale: this.refundData.refundScale / 100,
            }).then(res => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "退款完成，请查看微信支付记录"
                    });
                    this.showModel = false;
                    this.afterSalesCurrentPage = 1;
                    this.afterSalesGetList();
                } else {
                    this.$message.error(res.message)
                }
                this.loadingBtn = false;
            }).catch(err => {
                this.loadingBtn = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            });

        },
        /**@method 订单退款
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "订单退款";
            this.refundData = {
                nickeName: val.nickeName,
                orderNum: val.orderNum,
                orderId: val.orderId,
                refundScale: 100,
                refundRemark: val.refundRemark,
                payMoney: val.payMoney
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>