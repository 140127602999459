<template>
    <div class="view">
        <div class="pa24">
            <div class="d-flex">
                <div>
                    <el-radio style="margin-right: 0;" v-model="orderStatusValue" label="all" @change="searchFun"
                        border>
                        全部订单
                    </el-radio>
                    <el-radio style="margin-right: 0;" v-model="orderStatusValue" :label="id" @change="searchFun"
                        v-for="(name, id) in orderStatus" :key="id" border>
                        {{ name }}
                    </el-radio>
                    <el-input class="w200 ml10 mb10 mr10" placeholder="请输入订单编号" v-model="orderCode" />
                    <el-button type="primary" @click="searchFun">搜索</el-button>
                </div>
            </div>
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                <template v-slot:table>
                    <el-table-column prop="orderNum" align="center" label="订单编号" width="200" />
                    <el-table-column prop="coverImg" align="center" label="LOGO" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.companyLogo"
                                style="width: 100px; height: 100px;margin-bottom:-10px" :src="scope.row.companyLogo">
                            </el-image>
                            <p v-else style="width: 100px; height: 100px;line-height: 100px">
                                暂无图片
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" align="center" label="进驻社团的企业" show-overflow-tooltip />
                    <el-table-column prop="intoType" align="center" label="进驻类型" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ intoType[scope.row.intoType] }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="phoneNum" align="center" label="联系方式" show-overflow-tooltip />
                    <el-table-column prop="name" align="center" label="状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <!-- {{ orderStatus[scope.row.orderState] }} -->
                            <el-tag v-if="scope.row.orderState == 0" type="info">{{ orderStatus[scope.row.orderState] }}</el-tag>
                            <el-tag v-else-if="scope.row.orderState == 1">{{ orderStatus[scope.row.orderState] }}</el-tag>
                            <el-tag v-else type="danger">{{ orderStatus[scope.row.orderState] }}</el-tag>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getParkIntoOrderList, } from "@/api/propertyServices";
export default {
    name: "enterOrder",
    components: {
        commonTable
    },
    data() {
        return {
            //列表配置
            intoType: {
                0: "企服大厅", 1: "合作商家"
            },
            orderStatus: {
                0: "待付款", 1: "已付款", 3: "取消订单"
            },
            orderStatusValue: "all",
            orderCode: "",
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            tableData: [],
            parkId: 0,
        };
    },
    filters: {

    },
    created() {
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        this.getList();
    },
    computed: {
        watchCheckedPark() {
            return this.$store.state.checkedPark
        },
    },
    watch: {
        watchAssociation(row) {
            this.parkId = row.parkId;
            this.currentPage = 1;
            this.afterSalesCurrentPage = 1;
            this.getList();
        }
    },
    methods: {
        /**@method  */
        watchRefundScale() {
            this.showMoney = (this.refundData.payMoney * (this.refundData.refundScale / 100)).toFixed(2)
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                parkId: this.parkId,
                isPage: 1
            };
            if (this.orderStatusValue != 'all') {
                params.orderState = this.orderStatusValue
            }
            if (this.orderCode) {
                params.orderNum = this.orderCode
            }
            try {
                this.loading = true;
                let res = await getParkIntoOrderList(params);
                this.loading = false;

                const { data } = res;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>